<template>
  <div>

    <h2
      class="text-danger text-center"
      v-if="!show && this.$store.state.currentUser.membershipLevel == 'lite'"
    >
      <br /><br />Sorry this content is only for Standard and Annual Members<br /><br />
      <a class="text-warning" target="_blank" href="https://www.thedjsvault.com/join">Click here to upgrade!</a>
    </h2>

    <h2
      class="text-danger text-center"
      v-if="!show && this.$store.state.currentUser.membershipLevel == 'standard'"
    >
      <br /><br />Sorry this content is only for Annual Members<br /><br />
      <a class="text-warning" target="_blank" href="https://www.thedjsvault.com/vaultOTO">Click here to upgrade!</a>


      
    </h2>

    <section
      class="container-fluid"
      v-if="show"
    >

      <div class="row">
        <div class="col-lg-9">
          <div>
            <div id="vimeo"></div>
            <div class="row">
              <div class="col-sm-10 post-title">{{ post.Title }}</div>
              <div
                class="col-sm-2 float-right text-center"
                style="margin-top: 10px"
              >
                <post-actions :post="post"></post-actions>

              </div>
            </div>

            <p>
              <embed
                v-if="post.Document && post.Document.length >0"
                :src="post.Document"
                style="width: 100%; height: 100vh"
              />

              {{ post.Body }}
            </p>
            <div class="mt-2">
              <span
                class="mr-2 hashtag"
                v-for="(c, i) in post.Category"
                v-bind:key="i"
              ><span @click="searchTag(c)">#{{ c }}</span></span>
            </div>
          </div>

          <div
            class="row mt-2"
            v-if="post.Link"
          >
            <div class="col-md-12">
              <i class="fa fa-link mr-2"></i>
              <a
                class="text-primary pt-3"
                target="_blank"
                :href="post.Link"
              >{{
            post.LinkDescription
          }}</a>
            </div>
          </div>

          <div
            class="row mt-2"
            v-if="post.Link2"
          >
            <div class="col-md-12">
              <i class="fa fa-link mr-2"></i>
              <a
                class="text-primary pt-3"
                target="_blank"
                :href="post.Link2"
              >{{
            post.LinkDescription2
          }}</a>
            </div>
          </div>

          <div
            class="row mt-2"
            v-if="post.Link3"
          >
            <div class="col-md-12">
              <i class="fa fa-link mr-2"></i>
              <a
                class="text-primary pt-3"
                target="_blank"
                :href="post.Link3"
              >{{
            post.LinkDescription3
          }}</a>
            </div>
          </div>

          <div
            class="row mt-2"
            v-if="post.Link4"
          >
            <div class="col-md-12">
              <i class="fa fa-link mr-2"></i>
              <a
                class="text-primary pt-3"
                target="_blank"
                :href="post.Link4"
              >{{
            post.LinkDescription4
          }}</a>
            </div>
          </div>

          <div
            class="row mt-2"
            v-if="post.Link5"
          >
            <div class="col-md-12">
              <i class="fa fa-link mr-2"></i>
              <a
                class="text-primary pt-3"
                target="_blank"
                :href="post.Link5"
              >{{
            post.LinkDescription5
          }}</a>
            </div>
          </div>

          <Comments
            :post="post"
            :postId="this.$route.params.postId"
            class="pt-4"
          ></Comments>
        </div>
        <div class="col md-3">

          <a
            v-if="this.ads[0]"
            :href="this.ads[0].link"
            target="_blank"
          >
            <img
              :src="this.ads[0].image"
              class="img-fluid border border-dark rounded mt-1"
              alt=""
            /></a>

          <h4 class="mt-4">Related Videos</h4>
          <vertical :posts="relatedPosts.slice(0, 5)"></vertical>
        </div>

      </div>

    </section>

    <!-- <slider :title="'Related Content'" :posts="relatedPosts" v-if="relatedPosts"></slider> -->

  </div>
</template>

<script>
import { db } from "@/db.js";

import PostActions from "@/components/PostActions.vue";
import Vertical from "@/components/Vertical.vue";

import Comments from '../components/Comments.vue';


export default {
  components: { Comments, PostActions, Vertical },

  data: function () {
    return {
      post: [],
      postId: this.$route.params.postId,
      section: "",
      subsection: "",
      relatedPosts: [],
      show: false, //for hiding annual
      viewed: false,
      ads: []
    };
  },
  watch: {


    '$store.state.currentUser.membershipLevel': {

      immediate: true,
      handler: "getPost",
    },

    post () {
      var options = {
        url: this.post.Video,
        width: 1024,
        responsive: true,
      };

      var videoPlayer = new Vimeo.Player("vimeo", options);
    },
  },
  mounted () {
    this.getPost();

    this.$bind(
      "ads",
      db
        .collection("ads")

        // .orderBy("order", "desc")
        .limit(20)
    ).then(() => {

      let shuffled = this.ads.sort(() => Math.random() - 0.5)
      this.ads = shuffled;

    });
  },
  methods: {
    addToViewed () {
      console.log("POST", this.post);

      let currentViewed = [];
      if (this.post.Viewed) {
        currentViewed = this.post.Viewed;
      } else {
        console.log("no views")
      }

      currentViewed.push(this.$store.state.currentUser.uid);
      console.log("viewed", currentViewed);

      db.collection("posts")
        .doc(this.$route.params.postId)
        .update({
          Viewed: currentViewed,
        })
        .then(() => {
          this.viewed = true;
        });
    },
    searchTag (tag) {
      this.$router.push({ path: "/search", query: { tag: tag } });
    },
    getPost () {
      db.collection("posts")
        .doc(this.postId)
        .get()
        .then((snapshot) => {
          const post = snapshot.data();
          console.log(post);
          this.post = post;
          this.section = post.Section;
          this.subsection = post.Subsection;
          this.getRelatedPosts();



          if (
            this.section == "annual" && !this.$store.state.currentUser.membershipLevel == 'annual'
          ) {

            this.show = false;
          } else if (this.$store.state.currentUser.membershipLevel == 'lite' && !this.post.lite) {
            this.show = false;
          } else {
            this.show = true;
          }










          this.addToViewed();
        });
    },
    getRelatedPosts () {
      db.collection("posts")
        .where("Section", "==", this.section)
        .where("Subsection", "==", this.subsection)
        .get()
        .then((querySnapshot) => {
          // const documents = querySnapshot.docs.map((doc) => doc.data());

          querySnapshot.forEach((doc) => {
            let document = {};

            document = doc.data();
            document.id = doc.id;
            if (doc.id !== this.$route.params.postId) {
              this.relatedPosts.push(document);
            }
          });
        });
      //   .where("Section", "==", this.section)
      //  // .where("Subsection", "==", this.subsection)
      //  // .where("id", "!=", this.postId)
      //   //.orderBy("id", "desc")
      //   .get()
      //   .then((querySnapshot) => {
      //     const relatedPosts =  querySnapshot.docs.map(doc => doc.data())
      //     console.log("related", querySnapshot);
      //     this.relatedPosts = relatedPosts;
      //   })
      //   .then(() => this.slider(".favorites-slider"));
    },
    // slider(className) {
    //   jQuery(className).slick({
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 300,
    //     autoplay: false,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     // appendArrows: $('#sm-slick-arrow'),

    //     nextArrow:
    //       '<a href="#" class="slick-arrow slick-next"><i class= "fa fa-chevron-right"></i></a>',
    //     prevArrow:
    //       '<a href="#" class="slick-arrow slick-prev"><i class= "fa fa-chevron-left"></i></a>',
    //     responsive: [
    //       {
    //         breakpoint: 1200,
    //         settings: {
    //           slidesToShow: 3,
    //           slidesToScroll: 3,
    //           infinite: true,
    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           // arrows: false,
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //         },
    //       },
    //     ],
    //   });
    // },

    onPostClick (post) {
      this.$router.push(`/post/${post.id}`);
    },
  },
};
</script>

<style scoped>
.post-title {
  font-size: 36px;
  color: #ffffff;
}
.hashtag {
  font-size: 13px;
  color: red;
  cursor: pointer;

  padding: 0px;
}

.hashtag:hover {
  text-decoration: underline;
}
</style>
